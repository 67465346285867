import type Colors from '$/types/colors'

const colors: Colors = {
  black: '#000000',
  blue: {
    '05': '#f5f8fe',
    '30': '#adcdfc',
    '40': '#5d9eff',
    '50': '#1874FF',
    '80': '#001f4d',
  },
  bodyBackground: '#f2f4f8',
  dark: {
    '05': '#f2f4f8',
    '10': '#e0e2e4',
    '30': '#ccd2e3',
    '40': '#9aa1b7',
    '50': '#515767',
    '80': '#1e2022',
  },
  gray: {
    c: '#cccccc',
  },
  green: {
    '05': '#f7fcf8',
    '30': '#c8edce',
    '40': '#90dc9c',
    '50': '#60cc71',
    '80': '#133a19',
  },
  red: {
    '05': '#fef5f5',
    '30': '#fab9bd',
    '40': '#f4737b',
    '50': '#ef3742',
    '80': '#470609',
  },
  white: '#ffffff',
  yellow: {
    '05': '#fffbf5',
    '30': '#ffe8ba',
    '40': '#ffd175',
    '50': '#ffbc39',
    '80': '#4d3200',
  },
}

export default colors
