import { firestore } from '$/components/Firebase'
import { chunk } from '$/utils/arrays'
import type { RouteState, Stop, Stops } from '$/types'
import { maximumStopsInARoute } from '$/config'

import { getDefaultStopData } from './defaultData'
import * as RouteAPI from './route'

import type {
  DocumentReference,
  QuerySnapshot,
} from '@firebase/firestore-types'

export const getNewId = (routeRef: DocumentReference): string => {
  if (!routeRef) return ''

  return routeRef.collection('stops').doc().id
}

export const remove = async (
  route: RouteState,
  stop: Stop | null,
  id: string,
): Promise<void> => {
  if (!route?.ref) {
    console.error('No route provided. Cannot delete stop.')

    return
  }

  if (!id) {
    console.error('No stop id provided. Cannot delete stop.')

    return
  }

  await RouteAPI.setUnoptimizedIfNeeded(route)

  if (stop) {
    await route.ref.collection('stops').doc(id).delete()
  }
}

export const update = async (
  routeRef: DocumentReference,
  id: string,
  data: Record<string, unknown>,
): Promise<void> => {
  const stopDoc = routeRef.collection('stops').doc(id)

  await stopDoc.set(data, { merge: true })
}

export const save = async (
  routeRef: DocumentReference,
  stop: Stop,
  id: string,
): Promise<void> => {
  const stopData = { ...getDefaultStopData(), ...stop }

  if (!stopData.addressLineTwo) {
    stopData.addressLineTwo = ''
  }

  await update(routeRef!, id, stopData as Record<string, unknown>)
}

export const doSaveBatch = (routeRef: DocumentReference, stopDocs: Stop[]) => {
  const batch = firestore.batch()

  stopDocs.forEach((stop) => {
    const id = stop.id || routeRef.collection('stops').doc().id
    const stopData = { ...getDefaultStopData(), ...stop }
    const stopDoc = routeRef.collection('stops').doc(id)

    if (!stopData.addressLineTwo) {
      stopData.addressLineTwo = ''
    }

    batch.set(stopDoc, stopData, { merge: true })
  })

  return batch.commit()
}

export const batchSave = async (
  routeRef: DocumentReference,
  stops: Stop[],
): Promise<void> => {
  if (!routeRef) return

  const stopDocs = [...stops.values()]
  const chunksOfDocs = chunk(stopDocs, 500)

  chunksOfDocs.forEach(async (chunkOfDocs: Stop[]) => {
    await doSaveBatch(routeRef, chunkOfDocs)
  })
}

export const batchDelete = async (
  route: RouteState,
  stops: Stops,
): Promise<void> => {
  const routeRef = route?.ref

  if (!routeRef) return

  const stopDocs = [...stops.values()]
  const chunksOfDocs = chunk(stopDocs, 500)

  const promises = chunksOfDocs.map((chunkOfDocs: Stop[]) => {
    const batch = firestore.batch()

    chunkOfDocs.forEach((stop) => {
      if (!stop) return
      const stopDoc = routeRef.collection('stops').doc(stop.id)

      batch.delete(stopDoc)
    })

    return batch.commit()
  })

  await Promise.all(promises)

  return RouteAPI.setUnoptimizedIfNeeded(route)
}

export const subscribe = async (
  routeRef: DocumentReference,
  callback: (stops: QuerySnapshot) => void,
): Promise<() => void> =>
  routeRef!
    .collection('stops')
    .limit(maximumStopsInARoute)
    .onSnapshot((stopsSnapshot: QuerySnapshot) => {
      callback(stopsSnapshot)
    })
