import styled from 'styled-components'
import tw from 'twin.macro'

const AuthInput = styled.input`
  ${tw`
    w-full
    mb-2
    py-3
    px-4
    rounded-md
    bg-white
    border
    border-solid
    border-gray-300
    placeholder-gray-500
    focus:border-blue-500
    focus:outline-none
    text-sm
  `}

  &:first-of-type {
    ${tw`
      mt-8
    `}
  }

  &:last-of-type:not(:first-of-type) {
    ${tw`
      mb-8
    `}
  }

  & + button {
    ${tw`
      mt-4
    `}
  }
`

const Section = styled.section`
  ${tw`
    mb-6
    text-gray-800
  `}

  &:last-child {
    ${tw`
      mb-0
    `}
  }
`

const Title = styled.h1`
  ${tw`
    m-0
    mb-2
    text-3xl
    font-heading
    text-gray-800
    font-bold
    leading-8
  `}
`

const Footer = styled.footer`
  ${tw`
    text-center
    text-gray-500
    font-semibold
    text-xs
  `}
`

const FooterTwoItems = styled(Footer)`
  ${tw`
    flex
    space-x-4
    justify-end
 `}
`

const AuthButton = styled.button`
  ${tw`
    w-full
    flex
    items-center
    justify-center
    mb-2
    py-4
    px-4
    rounded-md
    bg-white
    border
    border-solid
    border-gray-300
    text-black
    font-semibold
    cursor-pointer
    hover:border-blue-800
    focus:border-blue-800
    focus:outline-none
  `}

  &:first-of-type {
    ${tw`
      mt-10
    `}
  }

  &:last-of-type {
    ${tw`
      mb-10
    `}
  }

  img {
    ${tw`
      w-6
      h-6
      mr-2
    `}
  }
`

const LinkButton = styled.button`
  ${tw`
    appearance-none
    p-0
    underline
    border-none
    bg-transparent
    cursor-pointer
    text-gray-500
    font-semibold
    text-xs
  `}
`

const Text = styled.p`
  ${tw`
    text-base
    font-normal
    text-gray-500
    leading-relaxed
  `}
`

const InputMessage = styled.div`
  ${tw`
    text-red-500
    text-xs
    pb-4
  `}
`

const DialogMessage = styled.div`
  ${tw`
    flex
    justify-center
    gap-2
    items-center
    bg-blue-500
    text-white
    text-sm
    font-bold
    py-3
    rounded-md
    mt-1
    mb-8
    bg-blue-100
    text-blue-800
  `}
`

const SuccessIcon = styled.div`
  mask-image: url('images/icons/success-icon.svg');
  ${tw`
    fill-current
    bg-blue-800
    w-6
    h-6
  `}
`

const BulletList = styled.ul`
  ${tw`
    box-border
    m-0
    pl-6
    text-gray-500
    list-disc
  `}
`

export {
  AuthInput,
  Section,
  Title,
  Footer,
  FooterTwoItems,
  AuthButton,
  LinkButton,
  Text,
  InputMessage,
  DialogMessage,
  SuccessIcon,
  BulletList,
}
