import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import {
  firebaseFirestoreHost,
  firebaseAuthHost,
  useEmulator,
  isBrowser,
  isDev,
} from '$/config'

const development = {
  apiKey: 'AIzaSyCmtUsl7xkyjdK8wdppp_pHFyRYShUcrxI',
  authDomain: 'circuit-dev-e0970.firebaseapp.com',
  databaseURL: 'https://circuit-dev-e0970.firebaseio.com',
  projectId: 'circuit-dev-e0970',
  storageBucket: 'circuit-dev-e0970.appspot.com',
  messagingSenderId: '608521717460',
}

const production = {
  apiKey: 'AIzaSyD_V2D6Sp3glWtiIztB096tqyc2_kkls_g',
  authDomain: 'circuit-prod.firebaseapp.com',
  databaseURL: 'https://circuit-prod.firebaseio.com',
  projectId: 'circuit-prod',
  storageBucket: 'circuit-prod.appspot.com',
  messagingSenderId: '741066876820',
}

try {
  const config = isDev ? development : production

  firebase.initializeApp(config)
} catch ({ message, stack }) {
  if (typeof message === 'string' && !/already exists/.test(message)) {
    console.error('Firebase initialization error', stack)
  }
}

const firestore = firebase.firestore()
const auth = firebase.auth()

if (useEmulator) {
  if (process.env.NODE_ENV === 'development' && isBrowser) {
    console.info(
      `%cAttached to firebase emulator.`,
      'font-size: 1.2rem; color: gold;',
    )
    console.info(
      '%cEmulator UI: http://localhost:4000',
      'font-weight: bold; color: gold;',
    )
  }

  console.log({
    useEmulator,
    firebaseAuthHost,
    firebaseFirestoreHost,
  })

  if (firebaseAuthHost) {
    auth.useEmulator(firebaseAuthHost)
  }

  if (firebaseFirestoreHost) {
    const [host, port] = firebaseFirestoreHost
      .replace(/https?:\/\//, '')
      .split(':')

    firestore.useEmulator(host, parseInt(port, 10))

    // firestore.settings({
    //   host: firebaseFirestoreHost,
    //   ssl: false,
    //   // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    //   experimentalForceLongPolling:
    //     typeof window !== 'undefined' && 'Cypress' in window,
    // })
  }
}

export default firebase
export { auth, firestore }
