import type { Stop } from '$/types'

interface AddressLines {
  addressLineOne: string
  addressLineTwo: string
}

export const buildFormattedAddress = ({
  addressLineOne,
  addressLineTwo,
}: Stop | AddressLines): string =>
  [addressLineOne, addressLineTwo].filter((_) => _).join(', ')
