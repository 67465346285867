import styled from 'styled-components'
import tw from 'twin.macro'

export const Button = styled.button`
  ${tw`
    w-full
    flex
    items-center
    justify-center
    appearance-none
    m-0
    p-0
    border-none
    cursor-pointer
    bg-sr-500
    rounded-md
    py-4
    px-5
    text-sm
    font-semibold
    text-white
    leading-none
    h-10

    hover:bg-sr-900

    focus:outline-circuit
    focus:ring

    disabled:bg-gray-300
    disabled:text-gray-500
    disabled:cursor-not-allowed
  `}

  img {
    ${tw`
      w-6
      h-6
      mr-2
    `}
  }
`

export const ButtonLike = styled(Button.withComponent('a'))``

export const BorderedButton = styled(Button)`
  ${tw`
    px-4
    text-sr-500
    bg-transparent
    border
    border-solid
    leading-none
    border-gray-300

    disabled:opacity-50
    hover:bg-gray-100
  `}
`

export const ButtonAction = styled.button`
  ${tw`
    bg-gray-100
    transition
    transition-colors
    duration-500
    ease-out
    relative
    border-none
    cursor-pointer
    flex
    flex-grow-0
    flex-shrink-0
    items-center
    justify-center
    ml-3
    rounded-md
    text-xl
    text-gray-500
    w-10
    h-10
    leading-none

    hover:bg-gray-200
    focus:outline-none
  `}
`

export const SecondaryButton = styled(Button)`
  /*
    justify-start and font-normal are not part
    of the design spec but since "add location" button
    is the only component using it, allow this local change
    to avoid creating a new component until needed.
  */
  ${tw`
    bg-gray-100
    border-0
    box-border
    text-sm
    text-gray-400
    leading-none
    justify-start
    font-normal

    hover:bg-gray-200
  `}
`
