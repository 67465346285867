import React from 'react'
import { FormattedMessage } from 'react-intl'

import Dialog from './Dialog'
import { Section, Title, Text } from '../Form/Auth.styled'
import { ConfirmDialogBody } from './Dialog.styled'
import { Button } from '../Button/Button.styled'

interface DialogStateProps {
  title: string
  text: string
  onConfirm: () => void
}

const ConfirmDialog: React.FC<DialogStateProps> = ({
  title,
  text,
  onConfirm,
}) => {
  return (
    <Dialog open>
      <Section>
        <Title>
          <FormattedMessage id={title} />
        </Title>
        <ConfirmDialogBody>
          <Text>
            <FormattedMessage id={text} />
          </Text>
          <Button type="button" onClick={onConfirm}>
            <FormattedMessage id="app.ok" />
          </Button>
        </ConfirmDialogBody>
      </Section>
    </Dialog>
  )
}

export default ConfirmDialog
