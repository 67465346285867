import React from 'react'

import { useDispatch } from '$/hooks'

import { DialogContainer, DialogBody } from './Dialog.styled'

interface DialogProps {
  open: boolean
  children: React.ReactNode
}

const Dialog: React.FC<DialogProps> = ({ open, children }) => {
  const dispatch = useDispatch()

  const onQuitDialog = () => {
    // Actually close the dialog
    dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' })
  }

  const onClickOutsideBounds = (event: React.MouseEvent<HTMLDialogElement>) => {
    const target = event.target as HTMLElement

    // DIALOG as a target means user clicked outside element
    // boundaries. In this clase we also close the dialog
    if (target.tagName === 'DIALOG') {
      dispatch({ type: 'UPDATE_OPEN_DIALOG', openDialog: 'none' })
    }
  }

  if (!open) return null

  return (
    <DialogContainer
      open={open}
      // Fired when user escapes (ESC) the dialog
      onCancel={onQuitDialog}
      // Listen for clicks. Used to listen for clicks outside element's area
      onClick={onClickOutsideBounds}
    >
      <DialogBody>{children}</DialogBody>
    </DialogContainer>
  )
}

export default Dialog
