import styled from 'styled-components'
import tw from 'twin.macro'
import { Modal } from 'react-dialog-polyfill'

export const DialogContainer = styled(Modal)`
  &::backdrop {
    ${tw`
      bg-black
      bg-opacity-75
    `}
  }

  ${tw`
    border-none
    bg-transparent
  `}

  * {
    ${tw`
      box-border
    `}
  }
`

export const DialogBody = styled('div')`
  ${tw`
    p-6
    pb-8
    max-w-lg
    rounded-md
    border-none
    bg-white
    rounded
    overflow-hidden
    shadow-lg
  `}
`

export const ConfirmDialogBody = styled.div`
  ${tw`
    grid
    grid-rows-2
    gap-6
  `}
`
