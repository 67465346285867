import { firestore } from '$/components/Firebase'
import type { RouteState } from '$/types'

import { getDefaultRouteData } from './defaultData'

import type { User } from '@firebase/auth-types'
import type {
  DocumentReference,
  QuerySnapshot,
  DocumentSnapshot,
} from '@firebase/firestore-types'

export const create = async (user: User): Promise<DocumentReference> => {
  const routeData = getDefaultRouteData(user.uid)

  return firestore
    .collection('users')
    .doc(user.uid)
    .collection('routes')
    .add(routeData)
}

export const subscribe = async (
  user: User,
  onSuccess: (doc: DocumentSnapshot) => void,
): Promise<() => void> =>
  firestore
    .collection('users')
    .doc(user.uid)
    .collection('routes')
    .orderBy('lastEdited', 'desc')
    .limit(1)
    .onSnapshot(async (routesSnapshot: QuerySnapshot) => {
      if (routesSnapshot.empty) {
        await create(user)

        return subscribe(user, onSuccess)
      }

      onSuccess(routesSnapshot.docs[0])
    })

export const getLatest = async (user: User): Promise<DocumentSnapshot> => {
  const routes = await firestore
    .collection('users')
    .doc(user.uid)
    .collection('routes')
    .orderBy('lastEdited', 'desc')
    .limit(1)
    .get()

  if (routes.empty) {
    await create(user)

    return getLatest(user)
  }

  return routes.docs[0]
}

export const update = async (
  ref: DocumentReference,
  data: Record<string, string>,
): Promise<void> => {
  await ref.update(data).catch(function (error) {
    console.error('Error updating document: ', error)
  })
}

export const setUnoptimizedIfNeeded = async (
  route: RouteState,
): Promise<void> => {
  if (
    route?.data?.state?.optimization === 'optimized' ||
    route?.data?.state?.optimization === 'optimizing'
  ) {
    await route.ref.set(
      {
        state: {
          optimization: 'creating',
          optimized: false,
        },
      },
      { merge: true },
    )
  }
}
