import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const SVG = styled.svg`
  ${tw`
    animate-spin
    -ml-1
    mr-2
    h-4
    w-4
    text-white
  `}
  > circle {
    ${tw`opacity-25`}
  }
  > path {
    ${tw`opacity-75`}
  }
`

export default function LoadingIcon(): JSX.Element {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </SVG>
  )
}
