import { keyframes } from 'styled-components'

const fadeInAnimation = keyframes`
  from { opacity: 0.25; }
  to { opacity: 1; }
`

const fadeOutAnimation = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`

export { fadeInAnimation, fadeOutAnimation }
