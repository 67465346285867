import styled, { css } from 'styled-components'
import tw from 'twin.macro'

import { fadeInAnimation, fadeOutAnimation } from '$/css/keyframes'

const FormHeader = styled.div`
  ${tw`
    font-semibold
    font-heading
    text-black
    mt-6
    mb-4
    flex
    items-center
    h-6
  `}

  > span {
    ${tw`
      font-normal
      mx-4
    `}
  }
`

const Select = styled.select`
  ${tw`
    bg-gray-100
    rounded-md
    h-10
    placeholder-gray-400
    text-sm
    rounded-md
    px-3
    w-full
    appearance-none
    leading-none
  `}
  background-image: url(/images/dropdown-arrow.svg);
  background-position: right 16px center;
  background-repeat: no-repeat;
`

const Section = styled.div`
  margin-bottom: 32px;
`

const MainSection = styled(Section)`
  ${tw`
    relative
    z-10
  `}
`

const Input = styled.input`
  ${tw`
      block
      container
      pt-3
      pb-3
      text-gray-800
      border
      border-solid
      border-gray-500
      border-2
      rounded-md
      placeholder-gray-400
  `}

  form {
    ${tw`
      p-8
      w-128
      rounded-md
      border-none
      shadow-lg
      bg-white
    `}
  }
`

const Textarea = styled.textarea`
  ${tw`
    block
    container
    p-3
    my-6
    text-gray-400
    border
    border-solid
    border-gray-300
    border-2
    rounded-md
    placeholder-gray-400
    leading-8
  `}
`

interface BatchGeocodeSectionProps {
  hidden: boolean
}

const BatchGeocodeSection = styled.section<BatchGeocodeSectionProps>`
  ${tw`
    relative
    flex
  `}

  animation-name: ${fadeInAnimation};
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  ${(p) =>
    p.hidden &&
    css`
      animation-name: ${fadeOutAnimation};
      animation-timing-function: ease-out;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    `}
`

export {
  FormHeader,
  Section,
  MainSection,
  Select,
  Input,
  Textarea,
  BatchGeocodeSection,
}
